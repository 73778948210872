import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const AwardsToggle: StyleRule<{ toggled: boolean }> = ({
    theme,
    toggled,
}) => ({
    cursor: 'pointer',
    position: 'relative',
    '& svg': {
        width: pxToRem(32),
        height: pxToRem(55),
    },
    [theme.screen.tablet]: {
        height: toggled ? pxToRem(112) : pxToRem(90),
        width: pxToRem(60),
        minWidth: pxToRem(60),
        transition: 'height 200ms ease',
        backgroundColor: theme.siteVariables.colors.charcoal['250'],
        ':hover': {
            height: pxToRem(112),
        },
        '& svg': {
            position: 'absolute',
            bottom: pxToRem(-15),
            height: pxToRem(105),
            width: '100%',
        },
    },
});

export const AwardsToggleSmall: StyleRule<{ toggled: boolean }> = ({
    theme,
    toggled,
}) => ({
    cursor: 'pointer',
    position: 'relative',
    '& svg': {
        width: pxToRem(32),
        height: pxToRem(55),
    },
    [theme.screen.tablet]: {
        height: toggled ? pxToRem(60) : pxToRem(55),
        width: pxToRem(37),
        minWidth: pxToRem(37),
        transition: 'height 200ms ease',
        backgroundColor: theme.siteVariables.colors.charcoal['250'],
        ':hover': {
            height: pxToRem(60),
        },
        '& svg': {
            position: 'absolute',
            bottom: pxToRem(-15),
            height: pxToRem(75),
            width: '100%',
        },
    },
});

export const AwardsTooltip: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: theme.siteVariables.fontSizes.caption04,
    borderRadius: pxToRem(15),
    height: pxToRem(30),
});
