import { useStyles } from '@archipro-design/aria';
import * as S from './AwardIndicator.style';
import React from 'react';
import { CustomAwardBadge } from '@archipro-design/icons';

interface AwardIndicatorProps {
    size?: 'sm' | 'lg';
    toggled?: boolean;
}

const AwardIndicator = ({ size, toggled = false }: AwardIndicatorProps) => {
    const { css } = useStyles({ toggled });
    return (
        <div
            className={css(
                size === 'sm' ? S.AwardsToggleSmall : S.AwardsToggle
            )}
        >
            <CustomAwardBadge />
        </div>
    );
};

export default AwardIndicator;
